.w-15 {
  width: 15%!important;
}

.w-20 {
  width: 20%!important;
}

.w-30 {
  width: 30%!important;
}

.w-60 {
  width: 60%!important;
}

#main .carousel-caption {
  text-align: left;
  bottom: 35%;
  left: 5%;
}

#main .carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 10px;
}

#main .hero {
  position: relative;
}

#main .hero img {
  width: 100%;
}

#main .main-img {
  height: 250px;
  width: 100%;
  background-size: 120vw;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

#main .carousel-item .main-img {
  height: 570px;
}

#main .hero-big .main-img {
  height: 570px;
}

#main .hero .content {
  position: absolute;
  bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  top: 52px;
  left: 60px;
  right: 60px;
  text-align: left;
}

#main .hero.hero-big .content {
  top: 165px;
  left: 189px;
}

#about h2 {
  font-size: 36px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: normal;
}

#about p {
  font-size: 16px;
  line-height: 2.19;
  text-align: justify;
}

#about img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

#about li img {
  display: inline-block;
}

img.icon-location {
  position: relative;
  top: -4px;
}

table.common-table.common-table-border {
  border: solid 1px #546a79;
}

table.common-table thead {
  background-color: #546a79;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
}

table.common-table tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

table.common-table td {
  color: #546a79;
  line-height: 1.25;
  border-top: none;
  vertical-align: middle;
}

table.common-table p {
  margin: 0;
  line-height: 1.25;
}

table.common-table .left-blue {
  color: #4a90e2;
  text-align: left;
}

table.common-table th,
table.common-table td {
  padding: 16px;
  text-align: center;
}

table.common-table .btn-link {
  color: #4a90e2;
}

#about .table.border {
  border: solid 1px #546a79!important;
}

#about .table thead th {
  border-bottom: none;
  white-space: initial;
  vertical-align: middle;
}

#about ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

#about li {
  line-height: 2.19;
}

ul.two-column-list,
.collapse-content {
  margin-left: 33px;
}

.two-column-list li {
  display: inline-block;
  width: 46%;
  color: #546a79;
}

#about .nav-tabs {
  border-bottom: none;
}

#about .nav-tabs .nav-link {
  border: 1px solid #546a79;
  border-radius: 0;
  border-right: none;
  color: #546a79;
  border-bottom: none;
  min-width: 192px;
  text-align: center;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}

#about .nav-tabs .nav-link:last-child {
  border-right: 1px solid #546a79;
}

#about .nav-tabs .nav-link:focus,
#about .nav-tabs .nav-link:hover {
  border-color: #546a79;
}

#about .nav-tabs .nav-item.show .nav-link,
#about .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #546a79;
  border-radius: 0;
}

#about .tab-content {
  border: 1px solid #546a79;
}

#about .table {
  margin-bottom: 0;
}

#about .table td,
#about .table th {
  border-top: none;
  padding: 16px;
}

#about .table td.content {
  word-break: break-all;
}

#about .table-striped tbody tr {
  color: #546a79;
}

#about .table-striped li {
  line-height: 1.5;
}

#about .table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

#about .card-header {
  border: solid 1px #ececec;
  background-color: #f9f9f9;
  padding: 0;
}

#about .accordion button::before {
  content: url(/public/media/icon-expand-more.svg);
  display: inline-block;
  vertical-align: middle;
  padding-left: 7px;
  padding-right: 7px;
  transition: all .3s ease-in-out;
}


#about .accordion button.collapsed::before {
  content: url(/public/media/icon-expand-more.svg);
  transform: rotate(-90deg);
}

#about .card-header h3 .btn-link {
  font-size: 20px;
  color: #546a79;
  padding: 5px;
  word-break: break-word;
  white-space: inherit;
  text-align: left;
}

#about .card-header h3 .btn-link:hover,
#about .card-header h3 .btn-link:focus {
  text-decoration: none;
}

#about .card-header .btn-link:focus {
  box-shadow: none;
}

#about .card-header h3.card-header-title {
  font-size: 20px;
  color: #546a79;
  word-break: break-word;
  white-space: inherit;
  text-align: left;
  font-weight: normal;
  margin-left: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
}

#about .box-card {
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  margin: 20px 25px;
  max-width: 364px;
  min-width: 300px;

}

#about .card {
  border-color: transparent;
}

#about .card-head {
  background-size: 100%;
  background-repeat: no-repeat;
  height: 220px;
  position: relative;
}

#about .md-box-head {
  width: 220px;
}

#about .card-head .card-content {
  position: absolute;
  bottom: 0;
  height: 81px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.04) 3%, rgba(0, 0, 0, 0.09) 7%, rgba(0, 0, 0, 0.16) 12%, rgba(0, 0, 0, 0.23) 18%, rgba(0, 0, 0, 0.31) 26%, rgba(0, 0, 0, 0.4) 34%, rgba(0, 0, 0, 0.49) 42%, rgba(0, 0, 0, 0.57) 51%, rgba(0, 0, 0, 0.64) 61%, rgba(0, 0, 0, 0.71) 70%, rgba(0, 0, 0, 0.76) 80%, rgba(0, 0, 0, 0.79) 89%, rgba(0, 0, 0, 0.8) 98%);
}

#about .box-card .card-text {
  font-size: 14px;
  line-height: 1.57;
  color: #2c2c2c;
  margin-bottom: 20px;
  min-height: 66px;
  text-align: center;
}

#about .box-card .card-title {
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  color: #2c2c2c;
  margin-bottom: 20px;
}

#about .box-card .card-body {
  padding: 5px 15px 20px 15px;
}

#about .box-card img {
  height: auto;
  width: 100%;
  padding: 15px 15px 21px 15px;
}

#about .box-card a.btn {
  font-size: 18px;
  line-height: 1;
  color: #2c2c2c;
  border-radius: 25px;
  border: solid 1px #2c2c2c;
  background-color: transparent;
  padding: 5px 26.5px;
}

#about .card-header-title ul {
  padding: 5px;
}

#about .card-header-title li {
  line-height: 1;
}

#about .card-header-title .nav-link {
  padding: 0;
  height: 16px;
  color: #546a79;
  font-weight: normal;
  font-size: 16px;
}

#about .card-header-title .dropdown-menu {
  top: 11px !important;
  left: -21px !important;
}

#about .card-header-title .dropdown-menu {
  border: solid 1px #ececec;
}

#about .card-header-title .dropdown-item {
  font-size: 16px;
  line-height: 1.13;
  color: #546a79;
  min-height: 40px;
  max-width: 200px;
  text-align: center;
  padding: 10px 0;
}

.unit {
  color: #636363;
}

#calendars-t1 p,
#calendars-t2 p {
  margin-bottom: 0;
}

#about figure.table {
  max-width: 100%;
  display: block;
  width: 100%;
  overflow-x: auto;
}

.min-width-115 {
  min-width: 115px;
}

.min-width-140 {
  min-width: 140px;
}

.max-width-1250 {
  max-width: 1250px;
}

.mb-41 {
  margin-bottom: 41px!important;
}

.mb-120 {
  margin-bottom: 120px!important;
}

@media only screen and (min-width: 1450px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 1450px) {
  #main .main-img {
    background-size: 100vw;
  }
}

@media only screen and (max-width: 1199px) {
  #main .main-img {
    background-size: cover;
  }

  #about .rwdtable {
    border: 0;
  }

  #about .rwdtable caption {
    font-size: 1.3em;
  }

  #about .rwdtable thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  #about .nav-tabs .nav-link {
    min-width: unset;
    flex: 1;
  }

  .card-deck .card {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  #about {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .card-deck .card {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  #main .content h1 {
    text-align: center;
  }

  #main .content h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  #main .hero .content {
    left: 15px;
    right: 15px;
  }

  #main .content h1 {
    font-size: 32px;
  }

  #main .content h2 {
    font-size: 16px;
  }

  .two-column-list li {
    display: inline-block;
    width: 100%;
    color: #546a79;
  }

  #about .md-box-container {
    width: 100%;
    display: inline-block;
  }

  #about .md-box {
    width: 100%;
    margin: 0 auto;
  }

  #about .md-box-head {
    margin: 15px auto;
  }
}
