@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap);
.admin_well__3p9Xi {
	background-color: #333;
}

.admin_stat-label__3pQYl {
	color: #ccc;
	text-transform: uppercase;
	font-weight: bold;
}

.admin_stat-value__1O0Tq {
	color: #fff;
	font-weight: bold;
	font-size: 3em;
}

.en {
	font-family: 'Roboto', sans-serif;
}

.tw {
	font-family: 'Roboto', 'Noto Sans TC', Microsoft Jhenghei, 'Heiti TC', 'WenQuanYi Zen Hei', sans-serif;
}

button:focus, input:focus{
  outline: none;
}

a {
  color: #4a90e2;
}

.general-hide {
  display: none;
}

.aspeed-color {
  color: #546a79;
}

nav {
  background-color: #fff;
}

.product-logo {
  height: 40px;
  display: inline-block;
}

.bg-dark .product-logo {
  -webkit-filter: invert(1);
          filter: invert(1);
}

nav .navbar-toggler {
  border-color: transparent;
  padding: 0;
}

.navbar-toggler .icon-bar {
  transition: all .3s;
  background-color: #546a79;
  width: 24px;
  height: 3px;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top: 0;
  display: block;
}

.navbar-toggler[aria-expanded=true] .icon-bar:first-child {
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 30px;
}

.navbar-toggler[aria-expanded=true] .icon-bar:nth-child(2) {
  background-color: #fff;
}

.navbar-toggler[aria-expanded=true] .icon-bar:nth-child(3) {
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin-bottom: 0;
  width: 30px;
}

.subscribe-mail {
  position: fixed;
  border-radius: 50%;
  top: 40%;
  right: 0;
  cursor: pointer;
  width: 95px;
  height: 80px;
  z-index: 100;
}

.scroll-up-arrow {
  position: fixed;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  bottom: 40px;
  right: 30px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 100;
}

.scroll-up-arrow img,
.subscribe-mail img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.subscribe-modal .modal-content {
  border-radius: 10px;
  border: solid 1px #474747;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 26px;
}

.subscribe-modal.modal-toast .modal-content {
  padding: 24.5px;
}

.subscribe-modal .modal-header {
  border-bottom: none;
  padding: 0;
}

.subscribe-modal h5.modal-title {
  color: #ffffff;
  font-size: 28px;
  font-weight: normal;
  width: 100%;
  text-align: center;
}

.subscribe-modal .close {
  color: #fff;
  text-shadow: none;
  opacity: 1;
  font-weight: normal;
  font-size: 25px;
  padding: 0;
  margin: 0;
}

.subscribe-modal .modal-body {
  padding: 24px 0 22px 0;
}

.subscribe-modal.modal-toast .modal-body {
  padding: 0;
}

.subscribe-modal .modal-body p {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 30px;
}

.subscribe-modal.modal-toast .modal-body p {
  font-size: 20px;
  margin-bottom: 0;
}

.subscribe-modal input {
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  padding-right:15px;
}

.subscribe-modal .modal-footer {
  border-top: none;
  padding-bottom: 0;
}

.subscribe-modal .modal-footer .btn {
  border-radius: 25px;
  border: solid 2px #ffffff;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  padding: 7px 26.5px;
}

.subscribe-modal .modal-footer .btn-primary {
  border-color: #7ed321;
  color: #7ed321;
}

.modal.receipt h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 0.88;
  color: #2c2c2c;
}

.modal.receipt p {
  color: #757575;
  font-size: 14px;
}

@media print {
  .modal.receipt .modal-body {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 60px;
    background: #FFF;
    -webkit-transform: translate(0, 24%);
            transform: translate(0, 24%);
  }
}

.modal.receipt .modal-footer {
  border-top: none;
}

.black {
  color: #000!important;
}

.navbar {
  padding: 9.5px 1rem;
  border-bottom: 1px solid #f3f3f3;
}

nav .navbar-nav .nav-item {
  position: relative;
}

nav .navbar-nav .nav-link {
  font-size: 16px;
  line-height: 1.33;
  color: #546a79;
  padding: 9px 27px;
  cursor: pointer;
  white-space: nowrap;
}

nav .navbar-nav .nav-link.active {
  color: #7ed321;
}

nav .navbar-nav .nav-link:hover {
  color: #7ed321;
}

.dropdown-menu {
  border: unset;
  border-radius: 0;
  padding: 0;
  /* top: 127%; */
  top: 122%;
}

.dropdown-item {
  word-wrap:break-word;
}

.navbar-main .dropdown-toggle::after {
  display: none;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #f8f9fa;
}

.navbar .dropdown-toggle {
  font-size: 14px;
  line-height: 1.43;
  color: #546a79;
  padding: .6rem 1rem;
}

.navbar .dropdown-item {
  font-size: 16px;
  line-height: 1.13;
  color: #546a79;
  min-height: 50px;
  max-width: 200px;
  text-align: center;
  padding: 15px 0;
}

.navbar .btn-search {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border-color: transparent;
  background-color: #f3f3f3;
  cursor: pointer;
}

.navbar .collapse-search {
  border-radius: 10px;
  border-color: transparent;
  background-color: #f3f3f3;
  padding: 6.6px;
  width: 100%;
  max-width: calc(100% - 402px);
  display: block;
  position: absolute;
  z-index: 1;
  right: 125px;
  display: none;
}

.collapse-search input {
  color: #546a79;
}

.navbar .collapse-search.show {
  display: block;
}

.navbar .collapse-search img {
  cursor: pointer;
}

.collapse-search button {
  background: transparent;
  background-image: url('/public/images/icon-search.svg');
  background-repeat: no-repeat;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  vertical-align: middle;
}

@-webkit-keyframes reSize
{
  from {
    width: 95px;
  }
  to {
    width: 78%;
  }
}

@keyframes reSize
{
  from {
    width: 95px;
  }
  to {
    width: 78%;
  }
}

.navbar .collapse-search input {
  background-color: #f3f3f3;
  border: unset;
  width: calc(100% - 59px);
}

.language .dropdown-menu {
  left: -78px;
  top: 124%;
  border: 1px solid #D7D7D7;
}

main {
  margin-top: 60px;
}

#main .hero h1 {
  font-size: 42px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 10px;
  text-shadow: 1px 1px 8px rgba(0,0,0,.5);
}

#main .hero h2 {
  font-size: 22px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 50px;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
}

footer {
  background-color: #546a79;
  padding-top: 58px;
}

.no-background-color {
  background-color: transparent;
}

footer h5 {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
}

footer ul {
  margin-bottom: 51px;
}

footer li {
  margin-bottom: 20px;
}

footer a {
  font-size: 14px;
  color: #ffffff;
}

footer a:hover {
  color: #ffffff;
}

footer .white-border {
  border-top: 1px solid #ffffff;
  padding-top: 20px;
}

footer .copyright {
  font-size: 14px;
  line-height: 1;
  text-align: right;
  color: #ffffff;
  margin-bottom: 82px
}

footer.no-background-color .copyright {
  color: #757575;
  text-align: center;
}

.list-disc li {
  list-style-type: disc;
  margin-left: 20px;
}

.contact-form {
  background-color: #fbfbfb;
  width: 100%;
  max-width: 640px;
  display: inline-block;
}

.contact-form span {
  display: block;
  position: relative;
}

.contact-form input {
  display: block;
  background-color: #fbfbfb;
  color: #546a79;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ececec;
  padding: 11px 15px;
}

.contact-form input::-webkit-input-placeholder, .contact-form textarea::-webkit-input-placeholder {
  color: #909090;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #909090;
}

.contact-form select {
  padding: 11px 12px;
}

.contact-form textarea {
  background-color: #fbfbfb;
  color: #546a79;
  width: calc(100% - 30px);
  border: none;
  margin: 11px 15px;
}

.btn-outline-aspeed {
  background-color: #FFFFFF;
  border-radius: 25px;
  border: solid 1px #546a79;
  color: #546a79;
  width: 100%;
  max-width: 200px;
  padding: 12px 0;
}

.btn-outline-aspeed:hover {
  background-color: #546a79;
  color: #ffffff;
}

.navbar.cookies {
  background-color: #546a79;
  padding: 20px;
}

.navbar.cookies {
  border-bottom: none;
}

.cookies p {
  color: #ffffff;
  line-height: 2.19;
}

.cookies button {
  color: #ffffff;
  background-color: transparent;
  border: solid 1px #ffffff;
  padding: 5px 46px;
}

.cookies a {
  font-weight: 500;
  color: #50e3c2;
  text-decoration: underline;
}

#menu {
  padding-top: 20px;
  padding-right: 20px;
  position: absolute;
  right: 0;
}

#menu .btn-menu {
  color: #6a6a6a;
  padding: 0.5px 11px;
  min-width: 70px;
  margin-right: 25px;
}

#menu .btn-menu:last-child {
  margin-right: 0;
}

#menu .btn-menu:hover {
  background-color: #f3f3f3;
}

#menu .btn-menu.active {
  border-radius: 8px;
  background-color: #7ed321;
  color: #FFFFFF;
}

#menu .btn-menu.active:hover {
  background-color: #65ae15;
}

#about h1 {
  font-size: 48px;
  letter-spacing: -1px;
  margin-top: 100px;
  margin-bottom: 40px;
}

#about .software-title {
  margin-top: 172px;
  text-align: center;
}

#about .software-content {
  margin-bottom: 109px;
}

#about h3 {
  font-size: 24px;
  color: #7ed321;
  line-height: 36px;
}

#about .software-box h3 {
  font-size: 48px;
  letter-spacing: -1px;
  color: #000000;
  line-height: 1;
}

#about .software-box {
  margin-bottom: 103px;
}

#about .software-table {
  width: 100%;
  max-width: 615px;
  margin-bottom: 125px;
}

#about .software-table td {
  vertical-align: middle;
}

#about .software-table p {
  font-size: 18px;
  line-height: 2;
  margin-bottom: 0;
}

#about .software-table tr:nth-of-type(odd) {
  
  background-color: #f9f9f9;
}

#about .software-table ul {
  list-style-type: "‧ ";
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 8px;
}

#about .btn-outline {
  border-radius: 25px;
  border: solid 2px #7ed321;
  background-color: transparent;
  color: #7ed321;
  font-size: 18px;
  padding: 1px 10px;
  min-width: 96px;
}

#about .btn-outline:hover {
  background-color: #7ed321;
  color: #FFFFFF;
}

#about .pricing-card {
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 300px;
  margin: 20px;
}

#about .pricing-card.best:before {
  content: url(/media/tag-bestvalue.svg);
  position: absolute;
  right: -1px;
  top: -1px;
}

#about .pricing-card h4 {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 52.4px;
}

#about .pricing-card .price {
  text-align: center;
  font-size: 56px;
  line-height: 1;
  margin-bottom: 4.6px;
}

#about .pricing-card .price::before {
  content: '$';
  font-size: 14px;
  position: relative;
  bottom: 30px;
}

#about .pricing-card .price::after {
  content: '/Month';
  font-size: 14px;
}

#about .pricing-card .text {
  font-size: 12px;
  line-height: 1.17;
  text-align: center;
  color: #9b9b9b;
  height: 14px;
  margin-bottom: 38px;
}

#about .pricing-card .number {
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  line-height: 0.88;
  color: #4a4a4a;
  margin-bottom: 20px;
}

#about .pricing-card .number.premium {
  color: #ff9500;
}

#about .pricing-card .number:first-letter {
  font-weight: 500;
}

#about .pricing-card .btn-outline,
#about .pricing-card .btn-solid {
  border-radius: 18px;
  font-size: 16px;
  padding: 4px;
  margin: 10px;
}

#about .pricing-card .btn-outline {
  border: solid 2px #7ed321;
}

#about .pricing-card .btn-outline:hover {
  background-color: #7ed321;
  color: #FFFFFF;
}

#about .pricing-card .btn-solid {
  background-color: #7ed321;
  color: #FFFFFF;
}

#about .pricing-card .btn-solid:hover {
  background-color: #65ae15;
}

#about .feature-item {
  color: #4a4a4a;
  line-height: 1;
  margin-bottom: 30px;
  text-align: initial;
}

#about .feature-item::before {
  content: '•';
  font-weight: 900;
  padding-right: 15px;
}

#slogan {
  background-image: url('/media/footer-download-bg.png');
  background-size: cover;
  padding-top: 61px;
  padding-bottom: 58px;
}

#slogan h1 {
  letter-spacing: -0.83px;
  color: #ffffff;
  margin-bottom: 33px;
}

#main .btn-solid,
#main .btn-outline,
#slogan .btn-solid,
#slogan .btn-outline {
  font-size: 18px;
  color: #ffffff !important;
  min-width: 192px;
  border-radius: 25px;
}

#about .form-control-lg {
  max-width: 400px;
  border-radius: 4px;
  border: solid 1px #cccccc;
  font-size: 16px;
  height: calc(1.5em + 1rem + 10px);
}

.sign-btn {
  font-size: 18px;
  color: #ffffff !important;
  width: 100%;
  max-width: 400px;
  border-radius: 25px;
}

.a-block-btn {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.btn-outline-default {
  font-size: 18px;
  width: 100%;
  border-radius: 25px;
  max-width: 400px;
  border: solid 1px #2c2c2c;
  padding: 10.5px 0;
}

.modal-footer .btn-outline-default,
.modal-footer .btn-outline-white {
  width: 40%;
}

.btn-outline-primary {
  font-size: 18px;
  width: 100%;
  border-radius: 25px;
  max-width: 400px;
  border: solid 2px #7ed321;
  padding: 10.5px 0;
  color: #7ed321!important;
}

.btn-outline-primary:hover {
  background-color: #7ed321;
  border: solid 2px #7ed321;
  color: #FFFFFF!important;
}

.btn-outline-white {
  font-size: 18px;
  width: 100%;
  border-radius: 25px;
  max-width: 400px;
  border: solid 2px #FFFFFF;
  padding: 10.5px 0;
  color: #FFFFFF;
}

.btn-outline-white:hover {
  color: #7ed321!important;
  border: solid 2px #7ed321!important;
}

.btn-link-gray {
  font-size: 14px;
  color: #757575;
  background-color: transparent;
  border: none;
  text-decoration: underline;
}

.btn-link-gray:hover {
  text-decoration: underline;
}

.btn-link-gray:focus {
  box-shadow: none;
}

#main .btn-solid,
#slogan .btn-solid,
.sign-btn {
  background-color: #7ed321;
  padding: 10.5px 0;
}

#main .btn-solid:hover,
#slogan .btn-solid:hover,
.sign-btn:hover {
  background-color: #65ae15;
}

.modal-footer .sign-btn {
  width: 40%;
}

#main .btn-outline,
#slogan .btn-outline {
  border: solid 2px #ffffff;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 9.5px 0;
}

#main .btn-outline:hover,
#slogan .btn-outline:hover {
  border: none;
  background-color: #7ed321;
  padding: 10.5px 0;
}

#main .carousel-inner h1 {
  font-size: 42px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 10px;
  text-shadow: 1px 1px 8px rgba(0,0,0,.5);
}

#main .carousel-inner h2 {
  font-size: 22px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 50px;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
}

/* SSO */
.disabled-text,
.password {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.disabled-text img,
.password img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.disabled-text img {
  cursor: not-allowed;
}

.sign-in {
  border-radius: 8px;
  background-color: #7ed321;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #ffffff!important;
  padding: 0.375rem 0.75rem;
}

.sign-in:hover {
  background-color: #65ae15;
  color: #ffffff;
}

.forgot-text {
  font-size: 14px;
  color: #757575;
  text-decoration: underline;
}

.sso-text {
  font-size: 14px!important;
  color: #2c2c2c;
  margin: 27px auto;
}

.signup-text {
  font-size: 14px!important;
  color: #757575;
}

.signup-text a {
  text-decoration: underline;
  color: #7ed321;
}

.login-btn {
  box-sizing: border-box;
  position: relative;
  margin: .2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: center;
  line-height: 50px;
  width: 100%;
  max-width: 400px;
  white-space: nowrap;
  border-radius: 100px;
  font-size: 16px;
  color: #2c2c2c;
  background-color: transparent;
  border: solid 1px #cccccc;
  display: block;
  margin: 15px auto;
}

.login-btn-facebook:before {
  background: url(/media/iconFbLogin.png) no-repeat;
}

.login-btn-google:before {
  background: url(/media/iconGoogleLogin.png) no-repeat;
}

.login-btn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  margin: 5px;
  width: 40px;
  height: 40px;
}

.faq ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.faq ol li {
  color: #0091ff;
}

.faq ul li:before {
  content: "‣ ";
}

.faq ul li {
  color: #000;
}

.faq li {
  line-height: 2.19;
}

.faq a {
  color: #7ed321;
  text-decoration: underline;
}

.faq h4 {
  font-weight: 500;
  line-height: 1.46;
  color: #7ed321;
}

.faq img {
  max-width: 600px;
  display: inline-block;
  margin-bottom: 1rem;
}

.form-max-width-100 .form-group {
  max-width: 100% !important;
}

.ck-editor__editable {
  min-height: 350px;
}

.form-group-inline {
  display: inline-block;
}

.form-group-inline label {
  font-weight: normal;
  margin: 0px 5px;
}

.max-width-125 {
  max-width: 125px !important;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 16px;
  line-height: 1.33;
  color: #546a79;
  padding: 9px 27px;
  cursor: pointer;
  white-space: nowrap;
}

@-webkit-keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }

  to {
    border-right-color: transparent;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }

  to {
    border-right-color: transparent;
  }
}

@-webkit-keyframes typewriter {
  from {
    width: 16rem;
  }

  to {
    width: 51rem;
  }
}

@keyframes typewriter {
  from {
    width: 16rem;
  }

  to {
    width: 51rem;
  }
}

@-webkit-keyframes fade-in {
  0% {opacity: 0;}
  40% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fade-in {
  0% {opacity: 0;}
  40% {opacity: 0;}
  100% {opacity: 1;}
}

@media only screen and (min-width: 576px) {
  .subscribe-modal .modal-dialog {
    max-width: 540px;
  }

  .subscribe-modal.modal-toast .modal-dialog {
    max-width: 540px;
    max-height: 234px;
  }
}

@media only screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 15px;
    padding-left: 15px;
  }

  .navbar-expand-lg .navbar-collapse {
    padding-left: 35px;
  }

  .desktop-hide {
    display: none !important;
  }

  .navbar .collapse-search.show {
    -webkit-animation: reSize ease-in-out 1s;
            animation: reSize ease-in-out 1s;
  }
}

@media only screen and (min-width: 768px) {

}

@media only screen and (max-width: 1285px) and (min-width: 1200px) {
  nav .navbar-nav .nav-link,
  .navbar .dropdown-toggle {
    padding: .6rem .62rem;
  }

  .navbar .collapse-search {
    max-width: calc(100% - 378px);
    right: 108px;
  }
}

@media only screen and (min-width: 1200px) {
  nav .container {
    max-width: 1440px;
    padding-right: 25px;
    padding-left: 25px;
  }

  nav .product-logo {
    margin-right: 34px;
  }

  nav .navbar-nav .nav-item.active::after,
  nav .navbar-nav .nav-item:hover::after {
    content: '';
    width: 100%;
    height: 10px;
    border-bottom: 2px solid #7ed321;
    display: block;
    position: absolute;
    z-index: 2;
  }

  .navbar-main .dropdown:hover>.dropdown-menu {
    display: block;
    top: 48px;
    z-index: 3;
    border: 1px solid #D7D7D7;
  }

  .navbar-main .dropdown .dropdown-item {
    line-height: 1.5;
    padding: 15px 5px;
    white-space: initial;
  }

  .navbar-main .dropdown>.dropdown-toggle:active {
      pointer-events: none;
  }
}

@media only screen and (min-width: 1450px) {

}

@media only screen and (max-width: 1199px) {
  body {
    padding-top: 42.55px !important;
  }

  .navbar-light .navbar-toggler {
    border: none;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #FFFFFF;
  }

  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar {
    padding: 4px 1rem;
  }

  .navbar .logo {
    width: 164px;
    height: auto;
  }

  .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }

  header nav.navbar-light {
    background: linear-gradient(180deg, #ffffff 44px, #546a79 44px);
    background: -o-linear-gradient(180deg, #ffffff 44px, #546a79 44px);
  }

  .navbar-collapse {
    margin-top: 20px;
  }

  .navbar-nav.navbar-main,
  .navbar-nav.navbar-lang-items {
    height: 100vh;
  }

  nav .navbar-nav .nav-link.active {
    color:#fff;
  }

  .navbar .collapse-search {
    position: relative;
    height: 40px;
    width: 100%;
    max-width: 100%;
    display: block;
    right: auto;
    margin-top: 10px;
    margin-bottom: 16px;
  }

  .navbar .btn-search, .close-searchbar {
    display: none;
  }

  .nav-back {
    text-align: left;
  }

  .nav-back::before {
    content: url(/media/icon-more-white.svg);
    padding-top: .6rem;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  .nav-back::after {
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    right: -15px;
    height: 1px;
    border-top: 1px solid #70828e;
    bottom: 5px;
  }

  nav .navbar-nav .nav-link, .navbar .dropdown-toggle {
    color: #ffffff;
    width: calc(100% - 31px);
    display: inline-block;
    font-size: 16px;
    padding: .875rem 0;
    background-color: transparent;
    border: none;
  }

  .navbar .language.show .dropdown-toggle {
    top: 48px;
    position: fixed;
    background-color: #546a79;
    padding-left: 29px;
  }

  .navbar .language.show .dropdown-toggle::before {
    content: url(/media/icon-more-white.svg);
    display: inline-block;
    vertical-align: middle;
    left: 6px;
    position: fixed;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    top: 52px;
}

  .role-element {
    display: block;
    position: fixed;
    width: 91%;
    left: 16px;
    bottom: 50px;
  }

  .role-element .sign-in {
    color: #ffffff;
    width: calc(100% - 31px);
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    padding: .938rem 0;
    background-color: transparent;
    border: none;
    text-align: left;
  }

  .navbar .language::after {
    content: url(/media/icon-more-white.svg);
    padding-top: .6rem;
    display: inline-block;
    vertical-align: middle;
    right: 6px;
    position: fixed;
  }

  .navbar .language {
    display: block;
    position: fixed;
    width: 91%;
    left: 16px;
    bottom: 0;
    opacity: 0;
    -webkit-animation: fade-in;
            animation: fade-in;
    -webkit-animation-duration: .8s;
            animation-duration: .8s;
    -webkit-animation:fade-in .8s;
  }

  .navbar .show .language {
    opacity: 1;
  }

  .navbar .language::before,
  .role-element::before {
    content: '';
    display: block;
    position: relative;
    width: 100vw;
    right: 15px;
    height: 1px;
    border-top: 1px solid #70828e;
  }

  .navbar .language .dropdown-toggle::after {
    display: none;
  }

  .navbar .language .dropdown-menu {
    position: fixed;
    top: 91px;
    /* background: linear-gradient(180deg, #ffffff 0px, #546a79 0px); */
    background-color: #546a79;
    height: 100%;
    left: 0;
    padding: 0 15px;
    border-left: none;
    border-right: none;
  }

  .navbar .language .dropdown-menu button {
    color: #ffffff;
    text-align: left;
  }

  main {
    margin-top: 44px;
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-show-block {
    display: block !important;
  }
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {
  #about h1 {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  footer .row {
    text-align: center;
  }

  footer .copyright {
    margin-bottom: 26px;
    text-align: center;
  }

  #main .btn-solid,
  #slogan .btn-solid {
    padding: 8px 0;
  }

  #main .btn-outline,
  #slogan .btn-outline {
    padding: 7px 0;
  }

  #main .btn-outline,
  #main .btn-solid,
  #slogan .btn-outline,
  #slogan .btn-solid {
    min-width: 145px;
  }

  #main .carousel-caption {
    width: 100%;
    bottom: 35%;
  }

  #main .carousel-inner h1 {
    font-size: 35px;
  }

  #main .carousel-inner h2 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  #slogan h1 {
    font-size: 1.6rem;
  }

  #about .software-box h3 {
    font-size: 28px;
    padding-top: 13px;
  }

  #about .software-title {
    margin-top: 50px;
  }

  #about .software-content {
    margin-bottom: 20px;
  }

  #about .software-box {
    margin-bottom: 20px;
  }

  #about .software-table {
    width: 90%;
  }

  #menu {
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    display: flex;
    padding: 5px 0 7px 5px;
    min-height: .01%;
    overflow-y: hidden;
    overflow-x: auto;
  }

  #menu .btn-menu {
    white-space: nowrap;
    min-width: auto;
  }

  .subscribe-mail {
    top: 70%;
  }

  #about .feature-item::before {
    padding-right: 8px;
  }
}

@media only screen and (max-width: 400px) {
  .cookies p {
    font-size: 14px;
    line-height: 1.43;
    margin-bottom: 6px;
    word-break: break-all;
  }
}

.w-15 {
  width: 15%!important;
}

.w-20 {
  width: 20%!important;
}

.w-30 {
  width: 30%!important;
}

.w-60 {
  width: 60%!important;
}

#main .carousel-caption {
  text-align: left;
  bottom: 35%;
  left: 5%;
}

#main .carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 10px;
}

#main .hero {
  position: relative;
}

#main .hero img {
  width: 100%;
}

#main .main-img {
  height: 250px;
  width: 100%;
  background-size: 120vw;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

#main .carousel-item .main-img {
  height: 570px;
}

#main .hero-big .main-img {
  height: 570px;
}

#main .hero .content {
  position: absolute;
  bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  top: 52px;
  left: 60px;
  right: 60px;
  text-align: left;
}

#main .hero.hero-big .content {
  top: 165px;
  left: 189px;
}

#about h2 {
  font-size: 36px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: normal;
}

#about p {
  font-size: 16px;
  line-height: 2.19;
  text-align: justify;
}

#about img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

#about li img {
  display: inline-block;
}

img.icon-location {
  position: relative;
  top: -4px;
}

table.common-table.common-table-border {
  border: solid 1px #546a79;
}

table.common-table thead {
  background-color: #546a79;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
}

table.common-table tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

table.common-table td {
  color: #546a79;
  line-height: 1.25;
  border-top: none;
  vertical-align: middle;
}

table.common-table p {
  margin: 0;
  line-height: 1.25;
}

table.common-table .left-blue {
  color: #4a90e2;
  text-align: left;
}

table.common-table th,
table.common-table td {
  padding: 16px;
  text-align: center;
}

table.common-table .btn-link {
  color: #4a90e2;
}

#about .table.border {
  border: solid 1px #546a79!important;
}

#about .table thead th {
  border-bottom: none;
  white-space: initial;
  vertical-align: middle;
}

#about ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

#about li {
  line-height: 2.19;
}

ul.two-column-list,
.collapse-content {
  margin-left: 33px;
}

.two-column-list li {
  display: inline-block;
  width: 46%;
  color: #546a79;
}

#about .nav-tabs {
  border-bottom: none;
}

#about .nav-tabs .nav-link {
  border: 1px solid #546a79;
  border-radius: 0;
  border-right: none;
  color: #546a79;
  border-bottom: none;
  min-width: 192px;
  text-align: center;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}

#about .nav-tabs .nav-link:last-child {
  border-right: 1px solid #546a79;
}

#about .nav-tabs .nav-link:focus,
#about .nav-tabs .nav-link:hover {
  border-color: #546a79;
}

#about .nav-tabs .nav-item.show .nav-link,
#about .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #546a79;
  border-radius: 0;
}

#about .tab-content {
  border: 1px solid #546a79;
}

#about .table {
  margin-bottom: 0;
}

#about .table td,
#about .table th {
  border-top: none;
  padding: 16px;
}

#about .table td.content {
  word-break: break-all;
}

#about .table-striped tbody tr {
  color: #546a79;
}

#about .table-striped li {
  line-height: 1.5;
}

#about .table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

#about .card-header {
  border: solid 1px #ececec;
  background-color: #f9f9f9;
  padding: 0;
}

#about .accordion button::before {
  content: url(/public/media/icon-expand-more.svg);
  display: inline-block;
  vertical-align: middle;
  padding-left: 7px;
  padding-right: 7px;
  transition: all .3s ease-in-out;
}


#about .accordion button.collapsed::before {
  content: url(/public/media/icon-expand-more.svg);
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

#about .card-header h3 .btn-link {
  font-size: 20px;
  color: #546a79;
  padding: 5px;
  word-break: break-word;
  white-space: inherit;
  text-align: left;
}

#about .card-header h3 .btn-link:hover,
#about .card-header h3 .btn-link:focus {
  text-decoration: none;
}

#about .card-header .btn-link:focus {
  box-shadow: none;
}

#about .card-header h3.card-header-title {
  font-size: 20px;
  color: #546a79;
  word-break: break-word;
  white-space: inherit;
  text-align: left;
  font-weight: normal;
  margin-left: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
}

#about .box-card {
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  margin: 20px 25px;
  max-width: 364px;
  min-width: 300px;

}

#about .card {
  border-color: transparent;
}

#about .card-head {
  background-size: 100%;
  background-repeat: no-repeat;
  height: 220px;
  position: relative;
}

#about .md-box-head {
  width: 220px;
}

#about .card-head .card-content {
  position: absolute;
  bottom: 0;
  height: 81px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.04) 3%, rgba(0, 0, 0, 0.09) 7%, rgba(0, 0, 0, 0.16) 12%, rgba(0, 0, 0, 0.23) 18%, rgba(0, 0, 0, 0.31) 26%, rgba(0, 0, 0, 0.4) 34%, rgba(0, 0, 0, 0.49) 42%, rgba(0, 0, 0, 0.57) 51%, rgba(0, 0, 0, 0.64) 61%, rgba(0, 0, 0, 0.71) 70%, rgba(0, 0, 0, 0.76) 80%, rgba(0, 0, 0, 0.79) 89%, rgba(0, 0, 0, 0.8) 98%);
}

#about .box-card .card-text {
  font-size: 14px;
  line-height: 1.57;
  color: #2c2c2c;
  margin-bottom: 20px;
  min-height: 66px;
  text-align: center;
}

#about .box-card .card-title {
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  color: #2c2c2c;
  margin-bottom: 20px;
}

#about .box-card .card-body {
  padding: 5px 15px 20px 15px;
}

#about .box-card img {
  height: auto;
  width: 100%;
  padding: 15px 15px 21px 15px;
}

#about .box-card a.btn {
  font-size: 18px;
  line-height: 1;
  color: #2c2c2c;
  border-radius: 25px;
  border: solid 1px #2c2c2c;
  background-color: transparent;
  padding: 5px 26.5px;
}

#about .card-header-title ul {
  padding: 5px;
}

#about .card-header-title li {
  line-height: 1;
}

#about .card-header-title .nav-link {
  padding: 0;
  height: 16px;
  color: #546a79;
  font-weight: normal;
  font-size: 16px;
}

#about .card-header-title .dropdown-menu {
  top: 11px !important;
  left: -21px !important;
}

#about .card-header-title .dropdown-menu {
  border: solid 1px #ececec;
}

#about .card-header-title .dropdown-item {
  font-size: 16px;
  line-height: 1.13;
  color: #546a79;
  min-height: 40px;
  max-width: 200px;
  text-align: center;
  padding: 10px 0;
}

.unit {
  color: #636363;
}

#calendars-t1 p,
#calendars-t2 p {
  margin-bottom: 0;
}

#about figure.table {
  max-width: 100%;
  display: block;
  width: 100%;
  overflow-x: auto;
}

.min-width-115 {
  min-width: 115px;
}

.min-width-140 {
  min-width: 140px;
}

.max-width-1250 {
  max-width: 1250px;
}

.mb-41 {
  margin-bottom: 41px!important;
}

.mb-120 {
  margin-bottom: 120px!important;
}

@media only screen and (min-width: 1450px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 1450px) {
  #main .main-img {
    background-size: 100vw;
  }
}

@media only screen and (max-width: 1199px) {
  #main .main-img {
    background-size: cover;
  }

  #about .rwdtable {
    border: 0;
  }

  #about .rwdtable caption {
    font-size: 1.3em;
  }

  #about .rwdtable thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  #about .nav-tabs .nav-link {
    min-width: unset;
    flex: 1 1;
  }

  .card-deck .card {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  #about {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .card-deck .card {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  #main .content h1 {
    text-align: center;
  }

  #main .content h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  #main .hero .content {
    left: 15px;
    right: 15px;
  }

  #main .content h1 {
    font-size: 32px;
  }

  #main .content h2 {
    font-size: 16px;
  }

  .two-column-list li {
    display: inline-block;
    width: 100%;
    color: #546a79;
  }

  #about .md-box-container {
    width: 100%;
    display: inline-block;
  }

  #about .md-box {
    width: 100%;
    margin: 0 auto;
  }

  #about .md-box-head {
    margin: 15px auto;
  }
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
}

.dark-card {
  background-color: rgb(51, 51, 51);
  border-color: rgb(51, 51, 51);
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

.hidden {
  display: none;
}

.section-home .jumbotron {
  background-color: #e7e7e7;
  text-align: center;
  padding: 30px 60px;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.section-home .jumbotron h1 {
  font-weight: bold
}

input[type=checkbox] {
  border-color: rgba(0,0,0,.08);
  color: transparent;
  background-color: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  line-height: initial;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-appearance: none;
}

input[type=checkbox]:checked {
  border-color: #7ed321;
  color: #fff;
  background-color: #7ed321;
}

input[type=checkbox]::before {
  left: 4px;
  top: 1px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  position: absolute;
}

#about .form-control-lg:focus {
  border: solid 1px #546a79;
  box-shadow: none;
}

.form-group {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

input[type="file"].form-control {
  height: auto;
}

.modal .form-group {
  max-width: none;
}

.has-error input {
  border: solid 1px #ff0000 !important;
}

.has-error::after {
  content: url(/media/icon-error.svg);
  display: block;
  position: absolute;
  right: 15px;
  top: 10px;
}

.admin .has-error::after {
  top: 37px;
}

h6 {
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  color: #2c2c2c;
}

#about p.gray {
  font-size: 24px;
  font-weight: 500;
  color: #cccccc;
  line-height: 1.5;
}

#about p.gray-sm {
  font-size: 14px;
  color: #757575;
  line-height: 1.43;
}

input[type=text]:disabled {
  background-color: #ffffff;
  color: #909090;
  cursor: not-allowed;
}

#news-title {
  font-size: 36px;
  line-height: .94;
  letter-spacing: normal;
  color: #000;
  margin-top: 36px!important;
  margin-bottom: 15px!important;
  font-weight: 400;
}

#news-date {
  font-size: 18px;
  line-height: 1.22;
  color: #546a79;
  margin-bottom: 38px;
}

@media (max-width:768px) {
  .section-home .jumbotron h1 {
      font-size: 49px
  }
}

@media (min-width:768px) {
  .section-home .jumbotron h1 {
      font-size: 105px
  }
}

body {
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  padding-top: 60px
}

select,textarea,input,.form-control {
  font-size: 16px
}

.bamf {
  font-size: 20em
}

