.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
}

.dark-card {
  background-color: rgb(51, 51, 51);
  border-color: rgb(51, 51, 51);
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

.hidden {
  display: none;
}

.section-home .jumbotron {
  background-color: #e7e7e7;
  text-align: center;
  padding: 30px 60px;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.section-home .jumbotron h1 {
  font-weight: bold
}

input[type=checkbox] {
  border-color: rgba(0,0,0,.08);
  color: transparent;
  background-color: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  line-height: initial;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-appearance: none;
}

input[type=checkbox]:checked {
  border-color: #7ed321;
  color: #fff;
  background-color: #7ed321;
}

input[type=checkbox]::before {
  left: 4px;
  top: 1px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  position: absolute;
}

#about .form-control-lg:focus {
  border: solid 1px #546a79;
  box-shadow: none;
}

.form-group {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

input[type="file"].form-control {
  height: auto;
}

.modal .form-group {
  max-width: none;
}

.has-error input {
  border: solid 1px #ff0000 !important;
}

.has-error::after {
  content: url(/media/icon-error.svg);
  display: block;
  position: absolute;
  right: 15px;
  top: 10px;
}

.admin .has-error::after {
  top: 37px;
}

h6 {
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  color: #2c2c2c;
}

#about p.gray {
  font-size: 24px;
  font-weight: 500;
  color: #cccccc;
  line-height: 1.5;
}

#about p.gray-sm {
  font-size: 14px;
  color: #757575;
  line-height: 1.43;
}

input[type=text]:disabled {
  background-color: #ffffff;
  color: #909090;
  cursor: not-allowed;
}

#news-title {
  font-size: 36px;
  line-height: .94;
  letter-spacing: normal;
  color: #000;
  margin-top: 36px!important;
  margin-bottom: 15px!important;
  font-weight: 400;
}

#news-date {
  font-size: 18px;
  line-height: 1.22;
  color: #546a79;
  margin-bottom: 38px;
}

@media (max-width:768px) {
  .section-home .jumbotron h1 {
      font-size: 49px
  }
}

@media (min-width:768px) {
  .section-home .jumbotron h1 {
      font-size: 105px
  }
}

body {
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  padding-top: 60px
}

select,textarea,input,.form-control {
  font-size: 16px
}

.bamf {
  font-size: 20em
}
